import { translate } from '@/domains/core/localization/translate';
export const {
  individualLoginText,
  individualLoginDescription,
  proLoginText,
  proLoginDescription,
  proPreRegisterDescription,
  proPreRegisterUrl
} = translate({
  "individualLoginText": "Sono un privato",
  "individualLoginDescription": "Accedere al mio account",
  "proLoginText": "Sono un Professionista",
  "proLoginDescription": "Accedere ai prezzi Pro",
  "proPreRegisterDescription": "Pre-register",
  "proPreRegisterUrl": "http://pro.manomano.com/de/"
});
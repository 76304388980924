import { translate, translateFormatter, type RichTextFormatter } from '@/core/localization/translate';
export const {
  leonidasHelloText,
  leonidasLoginText
} = translate({
  "leonidasHelloText": "Ciao",
  "leonidasLoginText": "Connettiti subito"
});
export const leonidasMobileLoginText = translateFormatter<{
  br: RichTextFormatter;
}>("Connettiti <br></br>subito", undefined, undefined, "it");
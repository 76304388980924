import { useSelector } from 'react-redux';

import type { SpartacuxProductDiscoveryDomainState } from '../../productDiscovery.model';

export const connectButtonVisibilitySelector = (
  state: SpartacuxProductDiscoveryDomainState,
): boolean =>
  state.productDiscovery?.headerLeonidas?.ui.connectButtonVisible || false;

export const useConnectButtonVisibility = () =>
  useSelector(connectButtonVisibilitySelector);

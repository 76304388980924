import {
  useEffect,
  useState,
  type FunctionComponent,
  type SyntheticEvent,
} from 'react';
import { useDispatch } from 'react-redux';

import { SafeStorage } from '@/core/data/utils/SafeStorage/SafeStorage';
import { useBooleanFlags } from '@/domains/core/flags/flags.hooks';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { signIn } from '@/domains/customerManagement/auth/services/client';
import { ABTests } from '@/domains/proB2BAnimation/abtests';
import { AccountButton } from '@/domains/productDiscovery/HeaderLeonidas/b2c/components/AccountButton/AccountButton';
import { LoginButton } from '@/domains/productDiscovery/HeaderLeonidas/b2c/components/LoginButton/LoginButton';
import { setConnectButtonVisibility } from '@/domains/productDiscovery/HeaderLeonidas/HeaderLeonidas.actions';
import { useConnectButtonVisibility } from '@/domains/productDiscovery/HeaderLeonidas/hooks/useConnectButtonVisibility';
import { GTM_CLICK_ON_LOGIN_ACCESS } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';
import { isMobile } from '@/domains/productDiscovery/HeaderLeonidas/utils/isMobile';
import { listLabel } from '@/productDiscovery/HeaderLeonidas/b2c/components/AnonymousAccount/translations';

import styles from './AnonymousAccount.module.scss';

export const CONNECT_DROPDOWN_OPENED = 'connect_dropdown_opened';

export const AnonymousAccount: FunctionComponent = () => {
  const dispatch = useDispatch();
  const connectButtonVisible = useConnectButtonVisibility();
  const [accountButtonSelected, setAccountButtonSelected] = useState('');

  const [isUnifiedPlatformEnabled] = useBooleanFlags([ABTests.PEPE_UNIFY_AUTH]);

  useEffect(() => {
    if (connectButtonVisible) {
      const onAnyOtherClick = () => {
        if (document.getElementById('didomi-popup')) {
          return;
        }
        dispatch(setConnectButtonVisibility(false));
      };

      document.addEventListener('click', onAnyOtherClick, true);

      return () => {
        document.removeEventListener('click', onAnyOtherClick, true);
      };
    }
  }, [dispatch, connectButtonVisible]);

  useEffect(() => {
    if (!isUnifiedPlatformEnabled) {
      const sessionStorage = SafeStorage.getSessionStorage();

      const connectDropdownOpened = sessionStorage.getItem(
        CONNECT_DROPDOWN_OPENED,
      );

      if (connectDropdownOpened !== 'true' && !isMobile()) {
        setTimeout(() => {
          dispatch(setConnectButtonVisibility(true));
          sessionStorage.setItem(CONNECT_DROPDOWN_OPENED, 'true');
        }, 300);
      }
    }
  }, [dispatch, isUnifiedPlatformEnabled]);

  const onClickHandler = (event?: SyntheticEvent) => {
    dispatch(setConnectButtonVisibility(!connectButtonVisible));
    Gtm.push(GTM_CLICK_ON_LOGIN_ACCESS());
    event?.stopPropagation();
  };

  const onClickHandlerV2 = (event?: SyntheticEvent) => {
    signIn({
      b2b: false,
      callbackUrl: window.location.href,
      unified: isUnifiedPlatformEnabled,
    });
    Gtm.push(GTM_CLICK_ON_LOGIN_ACCESS());
    event?.stopPropagation();
  };

  return (
    <div className={styles.container}>
      <LoginButton
        isExpanded={connectButtonVisible}
        onClick={isUnifiedPlatformEnabled ? onClickHandlerV2 : onClickHandler}
      />
      <div
        className={styles.body}
        data-expanded={connectButtonVisible ? 'true' : 'false'}
      >
        <ul aria-label={listLabel} className={styles.list} role="listbox">
          <li
            role="option"
            aria-selected={accountButtonSelected === 'isB2C'}
            onFocus={() => setAccountButtonSelected('isB2C')}
            onBlur={() => setAccountButtonSelected('')}
          >
            <AccountButton b2c />
          </li>
          <li
            role="option"
            aria-selected={accountButtonSelected === 'isB2B'}
            onFocus={() => setAccountButtonSelected('isB2B')}
            onBlur={() => setAccountButtonSelected('')}
          >
            <AccountButton />
          </li>
        </ul>
      </div>
    </div>
  );
};

AnonymousAccount.displayName = 'AnonymousAccount';

import classnames from 'classnames';
import type { FunctionComponent, SyntheticEvent } from 'react';

import { Image } from '@/core/Image/Image';
import AccountLogo from '@/productDiscovery/HeaderLeonidas/assets/anonymous.svg?url';

import {
  leonidasHelloText,
  leonidasLoginText,
  leonidasMobileLoginText,
} from './translations';

import styles from './LoginButton.module.scss';

interface Props {
  className?: string;
  isExpanded?: boolean;
  onClick?: (event?: SyntheticEvent) => void;
}

export const LoginButton: FunctionComponent<Props> = ({
  className,
  isExpanded = false,
  onClick = () => {},
}) => (
  <button
    type="button"
    className={classnames(styles.button, className)}
    onClick={onClick}
    aria-haspopup="listbox"
    aria-expanded={isExpanded}
    data-testid="login-button"
  >
    <div className={styles.labelsContainer}>
      <Image
        src={AccountLogo}
        alt="account"
        className={classnames(styles.logo, styles.anonymousLogo)}
        height={24}
        width={24}
        loading="eager"
      />
      <span className={styles.labels}>
        <span className={classnames(styles.title, styles.hideBelowMd)}>
          {leonidasHelloText}
        </span>
        <span className={classnames(styles.description, styles.hideBelowMd)}>
          {leonidasLoginText}
        </span>
      </span>
      <span className={classnames(styles.description, styles.hideAboveMd)}>
        {leonidasMobileLoginText.format({
          br: () => <br key="br" />,
        })}
      </span>
    </div>
  </button>
);

LoginButton.displayName = 'LoginButton';

import classnames from 'classnames';
import { useRef, type FunctionComponent } from 'react';

import { useBooleanFlags } from '@/domains/core/flags/flags.hooks';
import { useLazy } from '@/domains/core/page/hooks/useLazy';
import { getDomains } from '@/domains/core/platform/domains/getDomains';
import { getCurrentBaseUrl } from '@/domains/core/platform/getCurrentBaseUrl';
import { Link } from '@/domains/core/routing/components/Link';
import { routes } from '@/domains/core/routing/routes/routes.default';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { signIn } from '@/domains/customerManagement/auth/services/client';
import { ABTests } from '@/domains/proB2BAnimation/abtests';
import {
  individualLoginDescription,
  individualLoginText,
  proLoginDescription,
  proLoginText,
} from '@/domains/productDiscovery/HeaderLeonidas/b2c/components/AccountButton/translations';
import { GTM_CLICK_ON_MARKET_LOGIN } from '@/domains/productDiscovery/HeaderLeonidas/services/tracking/gtmEvents';

import styles from './AccountButton.module.scss';

interface Props {
  b2c?: boolean;
}

export const getLoginUrl = (isB2C?: boolean) =>
  isB2C ? routes.login : `${routes.login}?b2b=true`;

export const getDescriptionText = (isB2C?: boolean) =>
  isB2C ? individualLoginDescription : proLoginDescription;

const getB2BCallbackUrl = (): string => {
  const proOrigin = getCurrentBaseUrl(getDomains().domainPro);
  const { pathname, search } = window.location;
  return `${proOrigin}${pathname}${search}`;
};

export const AccountButton: FunctionComponent<Props> = ({ b2c = false }) => {
  const [isUnifiedPlatformEnabled] = useBooleanFlags([ABTests.PEPE_UNIFY_AUTH]);
  const ref = useRef<HTMLAnchorElement>(null);
  const { hasBeenDisplayed } = useLazy(ref);
  const loginUrl = getLoginUrl(b2c);
  const titleText = b2c ? individualLoginText : proLoginText;
  const descriptionText = getDescriptionText(b2c);
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();

    signIn({
      b2b: !b2c,
      callbackUrl: b2c ? window.location.href : getB2BCallbackUrl(),
      unified: isUnifiedPlatformEnabled,
    });

    Gtm.push(GTM_CLICK_ON_MARKET_LOGIN(b2c ? 'b2c' : 'b2b'));
  };
  return (
    <Link
      ref={ref}
      className={classnames(styles.link, {
        [styles.b2c]: b2c && hasBeenDisplayed,
        [styles.b2b]: !b2c && hasBeenDisplayed,
      })}
      href={loginUrl}
      onClick={handleClick}
      data-testid={b2c ? 'b2c-login' : 'b2b-login'}
    >
      <span className={styles.title}>{titleText}</span>
      <span className={styles.description}>{descriptionText}</span>
    </Link>
  );
};
